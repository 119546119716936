@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
	font-family: 'Magnolia';
	src: url('./fonts/Magnolia.otf') format('opentype');
}

@font-face {
	font-family: 'Swiss911';
	src: url('./fonts/Swiss911.otf') format('opentype');
}

button {
	font-family: 'Swiss911';
	cursor: url('./fonts/chain.png'), auto;
}

input {
	font-family: 'Swiss911';
}

body {
	cursor: url('./fonts/chain.png'), auto;
}

.result {
	/* display: flex;
	align-items: center;
	margin-bottom: 50px;
	padding: 50px; */
	/* max-w-xl; */
	/* max-h-xl; */
	/* overflow: auto; */
}

.letter {
	position: relative;
	padding: 0px 0.4rem;
}

.second-letter {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%) scale(0.9, 1);
	font-family: 'Magnolia';
	/* font-size: 200px; */
	color: rgba(0, 0, 0, 0.95);
	font-size: clamp(3rem, 5vw + 1rem, 3rem);
}

.empty {
	padding: 0 0.3em;
}

.alpha {
	font-family: 'Swiss911';
	/* font-size: 110px; */
	font-size: clamp(0.6rem, 4vw + 1rem, 2.25rem); /* Adjust values as needed */

	transform: scale(1.7, 0.8);
}

.loader {
	position: fixed; /* or 'absolute' if your body has relative positioning */
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(255, 255, 255, 0.9); /* White background with opacity */
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1000; /* Ensure it's above other content */
}

/* Hide the loader initially */
#loader {
	display: none;
}

/* #loader {
	display: flex;
} */
